<template>
  <li v-if="label">
    <h3 class="font-semibold" v-text="label"></h3>
    <div class="text-sm text-gray-700">{{event.date | date('LLLL')}}</div>
  </li>
</template>

<script>
export default {
  props: {
    event: {
      type: Object,
      required: true
    },
    subject: {
      type: String,
      default: 'shipment'
    }
  },
  computed: {
    label() {
      let labelId = `show.events.${this.event.type}`

      switch(this.event.type) {
        case 'status':
          return this.$t(`status.long.${this.event.data}`,{subject: this.$t(`show.${this.subject}`)})
        case 'created':
          return this.$t(`status.long.0`,{subject: this.$t(`show.${this.subject}`)})
        case 'bringoz':
          return this.$t(this.event.data)
        default:
            return this.$te(labelId) ? this.$t(labelId) : null
      }
    }
  }
}
</script>