<template>
  <div class="w-1/2 md:w-1/3 p-2">
    <h3 class="text-xs text-gray-600" v-text="label"></h3>
    <p class="text-md font-medium" :class="{'line-through': wrong}" v-text="value"></p>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: [String,Number],
      default: null
    },
    wrong: {
      type: Boolean,
      default: false
    }
  }
}
</script>