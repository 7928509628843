<template>
  <div>
      <h2 class="text-xl mb-4">{{$t('show.pickup-location',{location: location.name})}}</h2>
      <div v-if="image" class="location-image mb-1" :style="{'background-image': `url(${image})`}"></div>

      <a :href="mapLink" target="_blank" class="block mb-1">
        <div class="map-image" :style="{'background-image': `url(${mapImage})`}">
          <div v-if="location.hours" class="absolute whitespace-pre rounded text-sm m-2 p-2 bg-brand bg-opacity-70  hover:bg-opacity-90">
            <span>{{$t('show.opening-hours')}}:</span><br>
            <span class="font-medium">{{location.hours}}</span>
          </div>
        </div>
      </a>

      <div class="text-center mb-2">
        <Button anchor :href="mapDirections" class="border border-brand hover:bg-brand hover:bg-opacity-100 w-full sm:w-auto">
          <CursorIcon class="inline mb-1" /> {{$t('show.directions')}}
        </Button>
      </div>
  </div>
</template>

<script>
import CursorIcon from '../icons/Cursor.vue'
import Button from '../components/Button.vue'

export default {
  components: {
    CursorIcon,
    Button,
  },
  props: {
    location: {
      type: Object,
      required: true,
    }
  },
  computed: {
    image() {
      if(this.location.image) {
        return `${process.env.VUE_APP_IMAGE_HOST}${this.location.image}?w=700`
      } else return false
    },
    mapImage() {
      const {latitude,longitude} = this.location
      const key = process.env.VUE_APP_GOOGLE_API_KEY
      return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=16&size=640x640&key=${key}&markers=color:orange%7C${latitude},${longitude}`;
    },
    mapLink() {
      const {latitude,longitude} = this.location
      return `http://www.google.com/maps/place/${latitude},${longitude}`
    },
    mapDirections() {
      const {latitude,longitude} = this.location
      return `http://www.google.com/maps/dir//${latitude},${longitude}`
    },
  }
}
</script>