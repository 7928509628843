<template>
  <ul class="history">
    <template v-if="events.length">
      <Event v-for="(event,i) in events.reverse()" :key="i" :event="event" :subject="subject" />
    </template>
    <p v-else  class="text-md text-gray-600">{{$t('show.no-history')}}</p>
  </ul>
</template>

<script>
import Event from './Event.vue'

export default {
  components: {Event},
  props: {
    events: {
      type: Array,
      required: true
    },
    subject: {
      type: String,
      default: 'shipment'
    }
  },
}
</script>