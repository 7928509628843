<template>
  <div class="bg-gray-100 p-4 border-b border-gray-200">
    <ul class="timeline">
      <li 
        v-for="(status,i) in statuses" 
        :key="i"
        :class="{
          current: status == displayStatus,
          active: status <= displayStatus
        }"
      >
        <span>{{$t(`status.short.${status}`)}}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { allowedStatuses } from '../utils'

export default {
  props: {
    displayStatus: Number,
    allowedStatuses: Array
  },
  data() {
    return {
      statuses: allowedStatuses
    }
  }
}
</script>